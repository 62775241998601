import React from 'react';
import Header from '@components/Header';
import { Link, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import Page from '@components/Page';
import Footer from '@components/Footer';
import BackgroundImage from 'gatsby-background-image';
import { getCaseStudyUrl } from '../lib/urls';

function CaseCard({ casestudy, language, parent }) {
  let casestudyLink = getCaseStudyUrl({ parent, language, page: casestudy });
  return (
    <div className="d-flex col-xl-4 col-md-6 col-sm-12">
      <div className="card w-100">
        <Link to={casestudyLink}>
          <BackgroundImage
            alt="Image"
            className="card-img-top"
            style={{ height: '200px' }}
            fluid={casestudy.caseStudy.image.localFile.sharp.fluid}
          />
        </Link>
        <div className="card-body d-flex flex-column">
          <div className="mb-3">
            <GatsbyImage
              alt="Image"
              className="icon icon-md"
              fixed={casestudy.caseStudy.logo.localFile.sharp.fixed}
            />
          </div>
          <Link to={casestudyLink}>
            <h4>{casestudy.caseStudy.venueName}</h4>
          </Link>
          <p className="flex-grow-1 excerpt">
            {casestudy.caseStudy.excerpt}
          </p>
          <Link to={casestudyLink}>
            Read Story
          </Link>
        </div>
      </div>
    </div>
  );
}

export default function VideosTemplate({
  data: {
    page,
    casestudies
  },
  pageContext: { language, refs, layouts, configuration }
}) {
  return (
    <Page>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <div className="container">
        <div className="row align-items-stretch">
          {casestudies.nodes.map(casestudy =>
            <CaseCard
              key={casestudy.caseStudy.slug}
              casestudy={casestudy}
              parent={page}
              language={language}
            />
          )}
        </div>
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String, $lang: WpLanguageCodeEnum) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    casestudies: allWpCaseStudy(filter: {language: {code: {eq: $lang}}}) {
      nodes {
        properties {
          slug
          title
        }
        caseStudy {
          body
          description
          venueCategory
          venueName
          venueType
          venueLocation
          excerpt
          logo {
            localFile {
              sharp: childImageSharp {
                fixed(height: 36) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          image {
            localFile {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;